import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Post from "../components/post"
import ProjectDisplay from "../components/projectDisplay"
import styled from "@emotion/styled"

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`

const Section = styled.section`
  margin-bottom: 4rem;
`

const SectionHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1.5rem;
`

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  color: #111;
  margin: 0;
`

const ViewAll = styled(Link)`
  color: #666;
  text-decoration: none;
  font-size: 0.875rem;
  
  &:hover {
    color: #111;
    text-decoration: underline;
  }
`

const ScrollContainer = styled.div`
  display: flex;
  gap: 2rem;
  overflow-x: auto;
  padding: 0.5rem 0;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  
  &::-webkit-scrollbar {
    height: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: #666;
  }
`

const CardWrapper = styled.div`
  min-width: 300px;
  width: 300px;
  scroll-snap-align: start;
`

const HeroCard = styled.div`
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0.9) 100%
  );
  backdrop-filter: blur(10px);
  border-radius: 24px;
  padding: 4rem;
  margin: 2rem auto;
  max-width: 800px;
  box-shadow: 
    0 4px 6px rgba(0, 0, 0, 0.05),
    0 10px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
      radial-gradient(circle at 0% 0%, rgba(255, 182, 193, 0.1) 0%, transparent 50%),
      radial-gradient(circle at 100% 0%, rgba(173, 216, 230, 0.15) 0%, transparent 50%),
      radial-gradient(circle at 100% 100%, rgba(152, 251, 152, 0.1) 0%, transparent 50%),
      radial-gradient(circle at 0% 100%, rgba(255, 218, 185, 0.1) 0%, transparent 50%);
    z-index: -1;
  }
`

const HeroSection = styled.section`
  padding: 4rem 2rem;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 100%
  );
`

const HeroTitle = styled.h1`
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  background: linear-gradient(135deg, #000 0%, #333 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.2;
`

const HeroSubtitle = styled.p`
  font-size: 1.25rem;
  color: #666;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.6;
`

const SkillsSection = styled.section`
  padding: 4rem 2rem;
  background: #f8f9fa;
  margin: 4rem 0;
`

const SkillsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`

const SkillCard = styled.div`
  padding: 1.5rem;
  background: white;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  
  h3 {
    margin: 1rem 0;
    font-size: 1.25rem;
  }
  
  p {
    color: #666;
    font-size: 0.875rem;
  }
`

const ContactSection = styled.section`
  text-align: center;
  padding: 4rem 2rem;
  background: linear-gradient(to top, rgba(255, 255, 255, 0.9), transparent);
`

const ContactText = styled.p`
  font-size: 1.25rem;
  color: #666;
  margin-bottom: 2rem;
`

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  
  a {
    color: #111;
    font-size: 1.5rem;
    transition: transform 0.2s;
    
    &:hover {
      transform: translateY(-2px);
    }
  }
`

const HomePage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  const blogPosts = posts.filter(
    post => post.fields.slug.split("/")[1] !== "portfolio"
  )
  
  const portfolioPosts = posts.filter(
    post => post.fields.slug.split("/")[1] === "portfolio"
  )

  return (
    <Layout location={location} title={siteTitle}>
      <Seo 
        title="Phillip Michalowski - Software Engineer" 
        description="Projects and writing about software engineering, web development, and technology"
      />
      <Content>
        <Section>
          <SectionHeader>
            <SectionTitle>Latest Projects</SectionTitle>
          </SectionHeader>
          <ScrollContainer>
            {portfolioPosts.map(post => (
              <CardWrapper key={post.fields.slug}>
                <ProjectDisplay
                  title={post.frontmatter.title}
                  date={post.frontmatter.date}
                  link={post.fields.slug}
                  imageSrc={post.frontmatter.imageSrc || undefined}
                />
              </CardWrapper>
            ))}
          </ScrollContainer>
        </Section>

        <Section>
          <SectionHeader>
            <SectionTitle>Recent Writing</SectionTitle>
          </SectionHeader>
          <ScrollContainer>
            {blogPosts.map(post => (
              <CardWrapper key={post.fields.slug}>
                <Post
                  title={post.frontmatter.title}
                  date={post.frontmatter.date}
                  link={post.fields.slug}
                  imageSrc={post.frontmatter.imageSrc || undefined}
                  excerpt={post.excerpt}
                />
              </CardWrapper>
            ))}
          </ScrollContainer>
        </Section>
      </Content>
    </Layout>
  )
}

export default HomePage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          imageSrc
        }
      }
    }
  }
`
